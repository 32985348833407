import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import React from "react";
//import DoneIcon from '@mui/icons-material/DoneOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import {blue} from "@mui/material/colors/";
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@material-ui/core';



function App() {
  const {sessionid} = useParams();
  const [res,setResponse] = useState(null);
  //const [isLoading,setIsLoading] = useState(false);


  //  const color=green[500];

useEffect(() => {
//setIsLoading(true);
const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/installmentsuccess";
//  console.log(sessionid); // ▶ URLSearchParams {}
  //let data;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'plain/text');

  const params={sessionid:sessionid};
  const queryString = new URLSearchParams(params).toString();
//  console.log(queryString);

  fetch(API_URL+"?"+queryString,{method:'POST'})
    .then(response => response.json())
    .then(data =>
      { setResponse(data);
      //console.log(data);
    })
    .catch(err=>{console.log("ERROR reg success");})

},[]);

  return (
    <div >
      {res&& <div className="SuccessPage"
      style={{ width: "100%", display: "flex", justifyContent: "flex-end" ,  padding: "15px"}}>
        <CheckCircleIcon style={{ color:"green", fontSize:"40px" }}/>
          <Paper>Successfull Payment. An email was sent to <b>{res.email}</b>.<br/>Please check you spam/junk folder also.<br />The details of your payment are:<br/>
          <p><b>Payment Id: </b> {res.installmentId}</p>
            <p><b>Country: </b> {res.country}</p>
            <p><b>Email: </b> {res.email}</p>
          <p><b>First Name: </b> {res.firstName}</p>
            <p><b>Last Name: </b>{res.lastName}</p>
            {res.invoiceNumber&&<p><b>Invoice Number: </b>{res.invoiceNumber}</p>}
            {res.itemPaid&&<p><b>Item Paid: </b>{res.itemPaid}</p>}
            <p><b>Amount paid: </b>{res.totalFee}€</p>

            <p><b>Please save this information for future reference.</b></p>

            </Paper>

        </div>}
        {!res &&
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                      <CircularProgress color="secondary" />
        <span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
        </div>
        }
    </div>
  );
}
export default App;
