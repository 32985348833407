import {useState,useEffect} from "react";
import React from "react";
import axios from "axios";
//import { CircularProgress, Button } from '@material-ui/core';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { forwardRef } from 'react';

//import ButtonGroup from '@material-ui/core/ButtonGroup';
//import {Link,useHistory } from 'react-router-dom';

//import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
/**/
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Alert from '@mui/material/Alert';
//import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//import Select from '@material-ui/core/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';

import { useRef } from "react";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/';
import {  LocalizationProvider } from '@mui/x-date-pickers';
//import bgLocale from 'date-fns/locale/bg';
//import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import {  InputLabel, Select, MenuItem } from '@mui/material';

//import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Moment from 'react-moment';
//import 'moment-timezone';


import {useNavigate} from 'react-router-dom';
//import MuiPhoneNumber from 'mui-phone-number';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

//import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   input: {
//     background: "rgb(255, 192, 203)",
//     maxLength:"4",
//     size:"6"
//   }
// }));



const CreatePayment=() => {
  //const {section} = useParams();
  //const section = "Juror/Trainer";
  //console.log(section);
  //let formdate=  moment('2024-02-29', 'YYYY-MM-DD').format('YYYY-MM-DD');
  const todayDate= moment().format('YYYY-MM-DD');

  const initialValues = {president:"",country:"",
      invoiceNumber:"",fname:"",lname:"",dueDate:"",email:"",phone: ""
     ,pendingPayment:false,totalAmount:"",amountToPay:"",submitionDate:todayDate,
  pendingAmount:0, itemPaid:""
    };

  //FormValues
  const [formValues,setFormValues] = useState(initialValues);
  const [formErrors,setFormErrors] = useState([]);

//Pay
  const [isPay,setIsPay] = useState(false);

  const [isLoading,setIsLoading] = useState(false);
  const [validated,setValidated] = useState(false);

  const [loadingmessage,setLoadingmessage]=useState("Loading...Please wait");
  const [isChecked, setIsChecked] = useState(false);

  const [codeAccepted,setCodeAccepted] = useState(false);
  const [codeValid,setCodeValid] = useState(false);
  const [zeroRemaining,setZeroRemaining] = useState(false);
  //Accepted file types for online submition
//  const types = ['image/png', 'image/jpeg','image/jpg','image/gif','image/tiff'];
  const types = ['image/png', 'image/jpeg','image/jpg','image/gif','image/tiff', 'application/pdf'];

  const maxfilesize="10000000";
  const [countries, setCountries] = useState({});


  const navigate=useNavigate();

/*Dialog for Code*/
  const [open, setOpen] = React.useState(false);
  //const [selectedValue, setSelectedValue] = React.useState(codeAccepted);


  const pendingPaymentChange=(e)=> {
    const { name, value } = e.target;
   //  if (typeof formValues.pendingPayment === 'string') {
   //  console.log('formValues.pendingPayment is a string');
   // } else {
   //  console.log('formValues.pendingPayment is not a string');
   // }
 setFormValues({ ...formValues,[name]: value === 'true' });

};

// const pendingPaymentChange=(e)=> {
//  const { name, value } = e.target;
//
//
//  setFormValues({ ...formValues,[name]: value });
// };


const checkHandler = () => {
  setIsChecked(!isChecked)

};


   const inputStyle = {
    borderColor: formErrors.some(error => error.name === "phone") ? 'red' : '',
   };

   const secondBoxRef = useRef();

/*   const handleFirstBoxBlur = () => {
     secondBoxRef.current.focus();

     window.scrollTo({
       top: secondBoxRef.current.offsetTop-100,
       behavior: 'smooth'
     });
   };*/

  const handleClickOpen = async () => {
    const errors=[];
    let data;
    const ORG_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/invoice";

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'plain/text');

    let formatedDueDate= moment(formValues.dueDate).format('YYYY-MM-DD');
    const params={invoiceNumber:formValues.invoiceNumber,organisationCode:formValues.organisationCode};
    const queryString = new URLSearchParams(params).toString();
  //  console.log(queryString);


    if (!formValues.organisationCode)
      {errors.push({name:"organisationCode",value:"Organization Code is required!"});}
    else if (!formValues.invoiceNumber)
      {errors.push({name:"invoiceNumber",value:"Invoice Number is required!"});}
    //else if (!formValues.dueDate)
    //    {errors.push({name:"dueDate",value:"Invoice Due Date is required!"});}
    else{
            try {
            setIsLoading(true);

             // Make a GET request to your backend API endpoint using Axios
             const response = await axios.get(ORG_API_URL+"?"+queryString);
             let data = response.data;
          //   console.log(response);

             if (!response.data) {//Not valid combination
                //console.log('Data is empty');
                //setFormValues({ ...formValues,invoiceNumber:"",organisationCode:"",dueDate:""});
                setCodeValid(false);setOpen(true);
              }
              else {//Valid combination
              //  console.log('Data is not empty');
                //console.log(response.data);
                //Update the state with the retrieved data
                setFormValues({ ...formValues,amountPaid:response.data.amountPaid,country:response.data.country,dueDate:response.data.dueDate,
                  fullyPaid:response.data.fullyPaid,paymentType:response.data.paymentType,organisationName:response.data.organisationName,
                        president:response.data.president,totalAmount:response.data.totalAmount,pendingAmount:response.data.pendingAmount,
                        /*amountToPay:response.data.totalAmount - response.data.amountPaid < 0 ? 0 : response.data.totalAmount - response.data.amountPaid*/
});

                if (response.data.totalAmount - response.data.amountPaid <=0)
                  {setZeroRemaining(true);setCodeValid(true);  setOpen(true);}
                else {
                  setCodeValid(true);  setOpen(true);setZeroRemaining(false);
                }
              }
           } catch (error) {//Error from server
             // Handle errors
             //setFormValues({ ...formValues,invoiceNumber:"",organisationCode:"",dueDate:""});
             console.error('Error fetching data, please check yout connection and try again...', error);
             setCodeValid(false);setOpen(false);
             //setError('Error fetching data');
           } finally {
             // Set loading to false when the request is complete
             setIsLoading(false);
           }
        }
    setFormErrors(errors);

  };

  const handleCloseYes = (value: string) => {
    setOpen(false);
    setCodeAccepted(true);
  //  secondBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({
      top: secondBoxRef.current.offsetTop-100,
      behavior: 'smooth'
});

    //setCodeValid(true);
  };
  const handleCloseNo = (value: string) => {
    setOpen(false);
    setCodeAccepted(false);
    //setCodeValid(false);
  //  setFormValues({ ...formValues,invoiceNumber:"",organisationCode:"",dueDate:null});

  };

  const handleCloseDis = (value: string) => {
    setCodeAccepted(false);
    setOpen(false);
    //setCodeValid(false);
  //  setFormValues({ ...formValues,invoiceNumber:"",organisationCode:"",dueDate:null});
  };



  useEffect(async () => {    //console.log(newCategories.length);
//    let data;

let data;
setIsLoading(true);

const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/countries";
const response = await axios.get(API_URL1, data).then( response=>{
//console.log(response);
  return response.data;}).then(data=>
    {
    setCountries(data);
    //console.log(data);
}).catch(err=>{
  //console.log(err);
  alert("Error contacting server.Check your connection and try again later. " + err );

  //console.log(err);
})
setIsLoading(false);


      //console.log(isLoading+" "+isCountryLoading);
   },[formErrors]);


//Insert Code input
const codeChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});
}
//Function to "hear" Enter key button on code insert
const handleKeypress = (e) =>{
//console.log(e.key);
   if (e.key === "Enter" ) {
     document.getElementById("codebutton").click();
   }
 }

//Form Changes
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues,[name]: value});

      if(e.target.name==='amountToPay'){
          let newValue = e.target.value;
          // Filter out non-numeric characters
          newValue = newValue.replace(/[^0-9.]/g, '');

          // Remove the dollar sign from the new value
          const numericNewValue = parseFloat(newValue.replace(/[^0-9.-]+/g,""));

          /*if (numericNewValue > parseFloat(formValues.totalAmount-formValues.amountPaid)) {
            setFormValues({ ...formValues, ['amountToPay']: formValues.totalAmount - formValues.amountPaid < 0 ? 0 : formValues.totalAmount - formValues.amountPaid
          });}
          else{
          setFormValues({ ...formValues,['amountToPay']: Math.max(0,newValue)});}*/
          //console.log(newValue);
          setFormValues({ ...formValues,['amountToPay']: Math.max(0,newValue)});

        }
      /*else  if (e.target.name ==='pendFile')
         {//TODO: SETFORMERRORS
           //console.log(e.target.files[0]);
          // if (e.target.files[0]){
                //console.log(e.target);
                let selected=e.target.files[0];

               //Everything is OK
               if (selected && types.includes(selected.type) && selected.size < maxfilesize) {
                  setFormValues(prevFormValues => ({
                   ...prevFormValues,
                   [name]: selected,
                   ['fileName']: selected.name
                  }));
                 }
                else if (selected && selected.size>maxfilesize) {
                   e.target.value='';
                   setFormValues(prevFormValues => ({
                    ...prevFormValues,
                    [name]: '',
                    ['fileName']: ''
                   }));
                   //setFile('');
                   alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
                   //return {<Alert severity="error">Accepted file types: {types} Max file size: 5MB </Alert>}
                 }//Wrong file type
                else if (selected&&!types.includes(selected.type))
                {
                  e.target.value='';
                  setFormValues(prevFormValues => ({
                   ...prevFormValues,
                   [name]: '',
                   ['fileName']: ''
                  }));
                  alert('Accepted file types:'+ types);
                }
        //  }

      }*/
    };

const uploadFile3 = async (filetoup,filename,dirname) => {

        let res;
        setLoadingmessage("Uploading file: "+filename+ " for test "+dirname);
        const data = new FormData();
        data.append("file", filetoup);
        data.append("filename",filename);
        data.append("dirname",dirname);

        //console.log(formValues.idfile);
        const F_URL = process.env.REACT_APP_BACKEND_URL+"/services/files";
        //console.log(F_URL);
        try{
          res = await fetch(
          F_URL,
            {
              method: 'put',
              body: data,
              // headers: {}
            })
          //console.log(res);
        } catch (err) {
            //console.log(error);
            alert("Error while uploading files. Check your connection and try again. " + err );
            return false;
          }
          return res.ok;
      }

const handlePay = (e)=>{

  if(validate(formValues)) {
    setLoadingmessage("Redirecting...Please wait");
    setIsLoading(true);
    //setLoadingmessage("Validating...");
    setValidated(true);

    if(!isPay){
    setIsPay(true);
    document.getElementById('myForm').submit();
  }
    else {return;}
  }
  else {return;}
}

const handleSubmit = async (e) => {
 //e.preventDefault();

 const formData = new FormData();
 //console.log("submit");
 //formData.append('file', selectedFile);
 formData.append('invoiceNumber', formValues.invoiceNumber);
 formData.append('amountToPay', formValues.amountToPay);
 formData.append('fname', formValues.fname);
 formData.append('lname', formValues.lname);
 formData.append('email', formValues.email);
 formData.append('phone', formValues.phone);
 //formData.append('pendingPayment', formValues.pendingPayment);
 //formData.append('fileName', "");
 formData.append('country', formValues.country);
 formData.append('itemPaid', formValues.itemPaid);


 try {
 const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/services/InstallmentRequest`, {
 method: 'POST',
 body: formData,
 redirect: 'follow'

 });

 if (response.redirected) {
  window.location.href = response.url;
  return;
}

 if (!response.ok) {
 throw new Error(`HTTP error! status: ${response.status}`);
 }

 // Process the response here
 } catch (error) {
 console.error('There was an error!', error);
 }
};

  const validate=(values) => {
    setLoadingmessage("Validating...Please wait");
    //setIsLoading(true);
    //const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    //const phoneregex =^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$;
  //  console.log(formValues);
    const errors=[];
    //console.log(moment().diff(moment(formValues.dateofbirth), "years")>100);

    values.email=values.email.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.lname=values.lname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.fname=values.fname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');



      if(!values.email){
              errors.push({name:'email',value:"Email is required!"});
              //push({id: 4, name: "Mehul"})
        }
      if (!regex.test(values.email)){
              errors.push({name:'email',value: "Not a valid email format!"});
          }
  /*      if (!values.president)
            {errors.push({name:'president',value:"OMC Member Country President field is required!"});}
      if (!values.organisationName)
            {errors.push({name:"organisationName",value:"Member country Organization name is required!"});}
    if (!values.code || values.code.length>10)
        {errors.push({name:"code",value:"Country Organization Code is required!"});}*/
      if (!values.fname || values.fname.length>100)
        {errors.push({name:"fname",value:"Contact First Name is Required!"});}
      if (!values.lname || values.lname.length>100)
        {errors.push({name:"lname",value:"Contact Last Name is Required!"});}
      if (values.phone.length<8 || values.phone.length>20)
        {errors.push({name:"phone",value: "Enter a valid phone!"});}
      //Amount smaller than the invoice left.
      if (values.amountToPay <= 0 )
          {errors.push({name:"amountToPay",value: "Enter a valid amount!"});}
    // if (formValues.pendingPayment&&values.pendFile.length===0)
    //    {errors.push({name:"pendFile",value: "You Must Enter the Bank Wire Transfer Receipt to Continue!"});}


      /*console.log(errors);*/
      setFormErrors(errors);
      setIsLoading(false);
      if(errors.length===0)    return true;
      else return false;
  }

  return(

      <div className="create">

      <h1>OMC Payment Form</h1>
        {!formValues.code&&<Alert severity="info" variant="outlined">
        <b>Please enter the following infromation to proceed to payment.</b></Alert>}

    {/* <form id="myForm" action = {process.env.REACT_APP_BACKEND_URL+"/services/installment?invoiceNumber="+formValues.invoiceNumber+
        "&amount="+formValues.amountToPay+"&fname="+formValues.fname+"&lname="+formValues.lname+"&email="+formValues.email+"&phone="+formValues.phone
        +"&pendingPayment="+formValues.pendingPayment+"&comment="+formValues.comment+"&paymentType="+formValues.paymentType}
        method="POST" encType="multipart/form-data">*/}

        <form  id="myForm" action={process.env.REACT_APP_BACKEND_URL+"/services/installment"}
            method="post" encType="multipart/form-data">
    {/*       <form onSubmit={(e) => handleSubmit(e)}>*/}

        {false&&<div className="code">

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px' ,opacity: isLoading ? 0.5 : 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' , alignItems: 'stretch' }}>

        <label htmlFor="organisationCode" style={{ display: 'block', textAlign: 'left' }}>Organisation Code</label>
        <TextField  disabled={isLoading} id="organisationCode"
        type="text" required
        style ={{width: '50%' }}
        error={formErrors.some(error => error.name === "organisationCode")}
        name= "organisationCode"
        value={formValues.organisationCode}
        onChange={codeChange}
        onKeyPress={handleKeypress}
        />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch'  }}>
        <label htmlFor="invoiceNumber" style={{ display: 'block', textAlign: 'left' }}>Invoice number</label>
        <TextField disabled={isLoading}
        type="text" required
        style ={{width: '50%'}}
        InputLabelProps={{ style: { fontSize: '20px' } }}
        error={formErrors.some(error => error.name === "invoiceNumber")}
        name= "invoiceNumber"
        id= "invoiceNumber"
        value={formValues.invoiceNumber}
        onChange={codeChange}
        onKeyPress={handleKeypress}
        /></div>

        <Button disabled={validated||isLoading} id="codebutton" color="primary" variant="contained" onClick={handleClickOpen}  autoFocus>
        Proceed to Fill Form
        </Button>
        </Box>
      </div>}

      {false&&<Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      {!codeValid&&<div>
      <DialogTitle id="alert-dialog-title">
        {codeValid?"No remaining amount":"Not a  valid combination"}
      </DialogTitle>
        {!codeValid&&<Alert severity="error">{"Please enter a valid combination!"}</Alert>}
          <DialogActions>
            <Button onClick={handleCloseNo}>Close</Button>  </DialogActions></div>
      }
      {
        codeValid&&(zeroRemaining||formValues.pendingAmount>0)&&<div>
      <DialogTitle id="alert-dialog-title">
        {codeValid&&zeroRemaining?"Nothing to pay!":"There are pending payments!"}
      </DialogTitle>
        {<Alert severity="error">
        {codeValid&&zeroRemaining?"This invoice is allready paid in full!":"Cannot proceed to other payments until OMC processes them!"}
        </Alert>}
          <DialogActions>
            <Button onClick={handleCloseNo}>Close</Button>  </DialogActions></div>
      }
        {codeValid&&!zeroRemaining&&formValues.pendingAmount<=0&&<div>
        <DialogTitle id="alert-dialog-title">
          {"Accept information below?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {"Invoice number: " + formValues.invoiceNumber}<br />
          {"Country: "+formValues.country}<br />
          {"Organisation Name: " + formValues.organisationName}<br />
          {"President: "+formValues.president}<br />
          {"Due Date: " + moment(formValues.dueDate).format('YYYY-MM-DD') }<br />
          {"FullyPaid: " + (formValues.fullyPaid?"Yes":"No")}<br/>
          {"Payment Type: " + formValues.paymentType}<br />
          {/*"Total Invoice Amount: " + formValues.totalAmount +" $"*/}
          {"Amount Paid: " + formValues.amountPaid+" €"}<br/>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDis}>Disagree</Button>
          <Button onClick={handleCloseYes} autoFocus>
            Agree
          </Button>
        </DialogActions></div>
      }
      </Dialog>
    }
      <Box ref={secondBoxRef} tabIndex="-1" sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px',opacity: validated||isLoading ? 0.5 : 1 }}
      disabled={validated||isLoading} >

        <div className="reqfields" >

      {false&&  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch'  }}>
        <label htmlFor="dueDate" style={{ display: 'block', textAlign: 'left' }}>Due Date</label>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
        minDate={new Date("2024-01-01")}
        name="dueDate"

       views={['year', 'month', 'day']}
       openTo="year"
       inputProps={{ readOnly: true }}
       style={{ flex: 1 }}
       //format="YYYY-MM-DD"
       disabled={true}
       //minDate={new Date("1920-31-12")}
       emptyLabel="Select due date"
       error={formErrors.some(error => error.name === "dueDate")}
       value={formValues.dueDate}
       onChange={(newValue) => {
        setFormValues({ ...formValues,['dueDate']: newValue});
       }}
       renderInput={(params) => <TextField    id="dueDate"      error={formErrors.some(error => error.name === "dueDate")}
 required  style ={{width: '50%'}} readOnly  {...params} />}
/>
    </LocalizationProvider>
    </div>}

      {countries.length > 0 && (
          <FormControl size="small" fullWidth>
          <label htmlFor="country">Country</label>
          <Select
          disabled={validated || isLoading}
          style={{ width: '100%', display: 'block' }} // Ensure the Select itself takes full width and is displayed as block
          inputProps={{
            style: { textTransform: 'uppercase' },
            name: "country",
            id: "country",
            autoComplete: "off", // Explicitly set autocomplete to "off"
            value: formValues.country,
          }}
          onChange={handleChange}
          >
          {countries.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name.toUpperCase()}
            </MenuItem>
          ))}
          </Select>
          </FormControl>
  )}

            <label htmlFor="fname">Contact Person First Name</label>
            <TextField type="text" required
            error={formErrors.some(error => error.name === "fname")}
            disabled={validated||isLoading}
            style ={{width: '100%'}}
            name = "fname" value={formValues.fname}
            id = "fname"
            onChange={handleChange}/>

            <label htmlFor="lname">Contact Person Last Name</label>
            <TextField type="text" required
              error={formErrors.some(error => error.name === "lname")}
            disabled={validated||isLoading}
            style ={{width: '100%'}}
            name = "lname" value={formValues.lname}
            id = "lname"
            onChange={handleChange}
            />
            <label htmlFor="email">Contact Email</label>
            <TextField type="email" required
            error={formErrors.some(error => error.name === "email")}
            disabled={validated||isLoading}
            style ={{width: '100%'}}
            name= "email" id= "email"  value={formValues.email}
            onChange={handleChange}
            autoComplete="email"
            />

            <label id="contactPhoneLabel">Contact Phone</label>
            <PhoneInput required key="phone"   countryCodeEditable
            aria-label="Contact Phone"
            inputStyle={inputStyle}
            disabled={validated||isLoading}
            value={formValues.phone}
            onChange={(newValue) => {
             setFormValues({ ...formValues,['phone']: newValue});
            }}
            />
            <input type="hidden" name="phone" id="contactPhone" value={formValues.phone}/>
            </div>


          {/*  <Stack direction="column" className="agree">
            <label>
            <Checkbox color="default"
                      id="agree"
                      checked={isChecked}
                      onChange={checkHandler}/>
            I, the undesigned, have read and approve this discharge and release </label>
            <a href="https://omchairworld.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms&Conditions</a>
            </Stack>*/}

</Box>
          {/*<Alert severity="warning" variant="outlined"><b>Do not enter organisation code here. Ask your president for pending payment code which allows you to pay later.</b></Alert>*/}
          <Box ref={secondBoxRef} tabIndex="-1" sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px',opacity: validated||isLoading ? 0.5 : 1 }}
          disabled={validated||isLoading} >
          <legend>Payment Description</legend>

          <label htmlFor="invoiceNumber">Invoice Number (if any)</label>
          <TextField inputProps={{ maxLength: 30 }}
          id="invoiceNumber"
          disabled={validated||isLoading}
          style ={{width: '100%'}}
          name= "invoiceNumber" value={formValues.invoiceNumber}

          rows={1}
          variant="outlined"
          placeholder="Invoice Number..."
          onChange={handleChange}/>

          <label htmlFor="itemPaid">Item Paid</label>
          <TextField inputProps={{ maxLength: 30 }}
          id="itemPaid"
          disabled={validated||isLoading}
          style ={{width: '100%'}}
          name= "itemPaid" value={formValues.itemPaid}

          rows={1}
          variant="outlined"
          placeholder="Item Paid..."
          onChange={handleChange}/>
</Box>
        {false&&<Stack justifyContent="flex-start" sx={{ pl: 2, pt: 2, pb:1 }}  direction="row" spacing={2}>
         <RadioGroup
          aria-label="pendingPayment"
          name="pendingPayment"
          value={formValues.pendingPayment}
          onChange={pendingPaymentChange}
         >
          <FormControlLabel   disabled={validated||isLoading} value="true" control={<Radio />} label="Wire/Bank transfer" />
          {<div><input style={{ display: 'none' }}  key="pendFile" type="file" onChange={handleChange} id="pendFile"  name = "pendFile" accept={types}
          readOnly={ validated || isLoading||!formValues.pendingPayment} />
          {<label htmlFor="pendFile">Upload Wire/Bank Transfer Receipt<span className="reqfiles">*</span><br/>
             <Button variant="contained" color="secondary" size="small"
             disabled={validated||isLoading}
             style={{ opacity: formValues.pendingPayment ? 1 : 0.5 }}
              component="span">
               {formValues.pendFile?"Change Selection":"Upload file"}
             </Button>
             {formValues.pendFile&&<p style={{ fontWeight: 'bold', color: '#2196f3' , fontSize: '15px' }}>File selected: {formValues.pendFile.name}</p>}
            </label>}</div>}
          <FormControlLabel   disabled={validated||isLoading} value="false" control={<Radio />} label="Card Payment" />
         </RadioGroup>
       <input type="hidden" id="fileName" name="fileName" value={formValues.fileName.toLowerCase()} />
        </Stack>}


      {false&&  <FormControl sx={{ flex: 1 }}>
        <label style={{ fontWeight: "bold" }} >Remaining Invoice Amount</label>
        <TextField
        readOnly
        variant="outlined"
        disabled = {true}
        name= "totalAmount"
        value={formValues.totalAmount?formValues.totalAmount-formValues.amountPaid:0}
        InputProps={{
 startAdornment: <InputAdornment position="start">€</InputAdornment>,
}}
        />
          </FormControl>}

        <Stack direction="row" justifyContent="flex-end"  alignItems="center" sx={{ pb: 2 }}>
        <FormControl>
        <label htmlFor="amountToPay" style={{ fontWeight: "bold" }} >Payment Amount</label>
        <TextField
         disabled={validated||isLoading}
        variant="outlined"
        error={formErrors.some(error => error.name === "amountToPay")}
        onChange={handleChange}
        name= "amountToPay"
        id= "amountToPay"
        value={formValues.amountToPay}
        style={{ width: '100%'}}
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
          /*readOnly: true*/
        }}
        />
        </FormControl>
        </Stack>

          {formErrors.length>0&&<div className="errors">
          <Alert variant="outlined" severity="error">
          {formErrors.map((error,i)=>(<p key={i}>{error.value}</p>))}
          </Alert></div>}

          {<div>
            <Button endIcon={validated && <CreditCardIcon/>}
            disabled={isLoading}
            onClick={(event) => {
             event.preventDefault();

             // Get the form
             const form = document.getElementById('myForm');
             //console.log(form.elements);

             // Iterate over all input fields in the form
             // for (const input of form.elements) {
             //   // Skip unnamed or disabled fields
             //   if (input.name) {
             //     console.log(`Name: ${input.name}, Value: ${input.value}`);
             //   }
             // }
             //     document.getElementById('myForm').submit();
            //document.getElementById('myForm').addEventListener('submit', function() {
            //console.log("Submit");
            //document.getElementById('loadingSpinner').style.display = 'block';});

            handlePay();
            }}
            type="submit" variant="contained" autoFocus>
              "Pay now" {formValues.amountToPay}€
            </Button>
            </div>}

          {isLoading &&
          <div className="circular" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <CircularProgress thickness={1} size={150}/>
          <span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>{loadingmessage}</span>
          </div>
          }
        {isLoading &&false&&  <div id="loadingSpinner" style={{display: 'none'}}>Uploading...</div>}

        </form>
      </div>
  );
}

export default CreatePayment;
