import React, {useState, useEffect} from "react";

import {useNavigate,Link} from 'react-router-dom'

//import { Link } from '@mui/material';


import { TextField,Button,Stack,Box } from '@mui/material';
//import { Routes, Route, Link } from "react-router-dom";


import AuthService from "./services/auth.service";
//import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
//import AuthVerify from "./common/auth-verify";


const Login = ()=> {
  document.title = "Admin Login Page";


  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [message,setMessage] = useState("");
  const [currentUser,setCurrentUser]=useState(undefined);
  const navigate=useNavigate();

  const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    alert("Authentication error");
    return null;
  }
};


useEffect(()=>{


    if (AuthService.getCurrentUser())
    {
      //console.log( AuthService.getCurrentUser().accessToken);
      setCurrentUser(AuthService.getCurrentUser());
      if (AuthService.getCurrentUser()) {
        const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

        if (decodedJwt.exp * 1000 < Date.now()) {
          EventBus.dispatch("logout");
          navigate("/admin/Login");
        }
      }
    }
    else {
      //console.log("Already logged out");
      EventBus.dispatch("logout");
      //setCurrentUser("");
      //window.reload();
    };

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
  EventBus.remove("logout");
  };


},[]);

const logOut=()=> {
  AuthService.logout();
  setCurrentUser(undefined);
}


  const handleChange = (e) =>{

  if(e.target.name==='username'){
      setUsername(e.target.value);
  }
  else if(e.target.name==='password')
  setPassword(e.target.value);
}

const handleLogout=(e) =>{
//  console.log(AuthService.getCurrentUser());

  AuthService.logout();
  setCurrentUser(undefined);

  //console.log(AuthService.getCurrentUser());
}


  const handleLogin=(e) =>{

    e.preventDefault();

    setMessage("");
    setLoading(true);
    //this.form.validateAll();

    AuthService.login(username, password).then(
        () => {

      setCurrentUser(AuthService.getCurrentUser());
      navigate("/admin/Payments");
      setLoading(false);

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

            setLoading(false);
            setMessage(resMessage);
        }
      );
  }


    return (
          <div className="pendingpayments">
        <div className="card card-container">
        {!currentUser&&
          <form onSubmit={handleLogin}   >
          <Box justifyContent="center"
          alignItems="center" spacing={1}>
            <div className="form-group">
              <span className="login">Username</span>
              <TextField
              height="30"
                 size="small"
                placeholder="Username"
                type="text"
                variant="outlined"
                className="form-control"
                name="username"
                value={username}
                onChange={handleChange}
                required
            />
            </div>

            <div className="form-group">
              <span className="login">Password</span>
              <TextField
                 size="small"
              placeholder="Password"
              variant="outlined"
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={handleChange}
                required
              />
            </div>
            {/*0mcM0dP@$$*/}

            <div className="form-group">
              <Button type="submit" disable={loading?1:0}
                className="btn btn-primary btn-block"
              >
                {/*loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )*/}
                <span>Login</span>
              </Button>

            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
    {/*        <Button
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />*/}
          </Box>
          </form>}
          {currentUser &&
          <Stack direction="column" justifyContent="left" alignItems="left" spacing={0}>
          <label>Logged in as {currentUser.username}</label>
          <Button onClick={handleLogout}
            className="btn btn-primary btn-block">
              <span className="spinner-border spinner-border-sm"></span>
            <span>Logout</span>
          </Button>
          <Button
            className="btn btn-primary btn-block">
              <span ></span>
          <Link style={{ textDecoration: 'none' }} to={"/admin/Payments"}>Back to Admin</Link>
          </Button>
          </Stack>}
        </div>

      </div>
    );
  }
  export default Login;
