import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import { render } from 'react-dom';
import Navbar from './Navbar';
import CreatePayment from './CreatePayment';
import SuccessPage from './SuccessPage';
import PendingSuccessPage from './PendingSuccessPage';
import CancelPage from './CancelPage';
import NotFoundPage from './NotFoundPage';
import Payments from './Payments';
import Login from "./Login";


import {useEffect} from "react";
//import {useParams} from 'react-router-dom';

function App() {
  document.title = "OMC Payment";
  //const {section} = useParams();

  useEffect(() => {
  //const {section} = useParams();
  //console.log(section);
   },[]);

  return (
    <BrowserRouter>
<div className='App'>
<Navbar />
<div className="content">

      <Routes>
      <Route path="*" element={<NotFoundPage />} />
          <Route  path="/Payment" exact={true}  element={<CreatePayment />} />
          <Route  path="/SuccessPage/:sessionid"   element={<SuccessPage />} />
          <Route  path="/PendingSuccessPage/:installmentid"   element={<PendingSuccessPage />} />
          <Route  path="/CancelPage"   element={<CancelPage />} />
          <Route  path="/admin/Payments" exact={true}  element={<Payments />} />
          <Route  path="/NotFoundPage"  element={<NotFoundPage />} />
          <Route  path="/admin/Login" exact={true}  element={<Login />} />

      </Routes>
      </div>
</div>
</BrowserRouter>
  );
}
//render(<App />, document.querySelector('#app'));

export default App;
